import "./registerServiceWorker";

import { datadogRum } from "@datadog/browser-rum";
import VWave from "v-wave";
import { createApp } from "vue";
import { config, name, version } from "../package.json";
import App from "./App.vue";
import { AppSettings } from "./plugins/app-settings/AppSettings";
import { ConsoleTransport } from "./plugins/logger/ConsoleTransport";
import { VueLogger } from "./plugins/logger/VueLogger";
import router from "./router";
import store, { key } from "./store";

if (process.env.NODE_ENV === "production") {
  datadogRum.init({
    applicationId: "343a93fa-aa85-41ed-bfac-2d855aacb786",
    clientToken: "pub9067cdc7dec1fa4b8ae8077536e08a9f",
    site: "datadoghq.com",
    service: name,
    env: "web",
    version: version,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}

createApp(App)
  .use(store, key)
  .use(router)
  .use(VWave)
  .use(VueLogger, {
    level: config.logLevel,
    transports: [ConsoleTransport()],
  })
  .use(AppSettings, {
    mode: "mobile",
    ...config,
  })
  .use((app) => {
    const errorHandler = app.config.errorHandler;
    app.config.errorHandler = function (err, instance, info) {
      datadogRum.addError(err, { info, component: instance?.$options.name });

      if (errorHandler) {
        errorHandler(err, instance, info);
      }
    };
  })
  .mount("#app");
